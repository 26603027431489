import * as React from 'react';
import { graphql, PageProps } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCalendarDay } from '@fortawesome/free-solid-svg-icons';

import PostLayout from '../components/postLayout';
import Seo from '../components/seo';
import Bio from '../components/bio';
import ShareButton from '../components/share-button';
import { BlogPostBySlugQuery } from '../../types/graphql-types';

const BlogPostTemplate: React.FC<PageProps<BlogPostBySlugQuery>> = ({
  data,
  location,
}) => {
  const post = data.markdownRemark;
  const siteTitle = data.site.siteMetadata?.title || 'Title';
  const pageUrl = `${data.site.siteMetadata?.siteUrl}${post.fields.slug}`;
  // const { previous, next } = data

  const createdAt = post.frontmatter.date;
  const updatedAt = post.frontmatter.modifieddate;

  return (
    <PostLayout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
        keywords={post.frontmatter.keywords}
      />

      <article itemScope itemType="http://schema.org/Article" className="p-4">
        <header>
          {/* <!--Title--> */}
          <div className="pt-16 md:pt-32">
            <h1 className="font-bold break-normal text-center text-3xl md:text-5xl">
              {post.frontmatter.title}
            </h1>
          </div>
        </header>
        {/* <!--Container--> */}
        <div className="container max-w-5xl mx-auto">
          <div className="mx-0 sm:mx-6">
            <div className="w-full p-8 md:p-24 text-xl md:text-2xl">
              {/* <ShareButton title={post.frontmatter.title} url={pageUrl} /> */}
              <p className="text-sm md:text-base font-bold text-right">
                <span className="mr-2">
                  <FontAwesomeIcon icon={faCalendarDay} size="xs" />
                </span>
                {createdAt}
                {updatedAt && (
                  <span className="ml-2 font-normal">({updatedAt} 更新)</span>
                )}
                {post.fields.readingTime.text && (
                  <>
                    <span className="mx-2">
                      <FontAwesomeIcon icon={faClock} size="xs" />
                    </span>
                    {post.fields.readingTime.text}
                  </>
                )}
                {/* <span className="text-gray-900">/</span> GETTING STARTED */}
              </p>

              {/* Table of Contents */}
              {post.tableOfContents && (
                <div className="mt-2 p-2 markdown">
                  <div className="font-medium">目次</div>
                  <div
                    className="mt-2"
                    dangerouslySetInnerHTML={{ __html: post.tableOfContents }}
                  />
                </div>
              )}

              {/* <!--/ Post Content--> */}
              <section>
                <div
                  className="mt-2 markdown"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                  itemProp="articleBody"
                />
              </section>
              {/* <footer className="mt-10 mb-5">
              <ul
                style={{
                  display: `flex`,
                  flexWrap: `wrap`,
                  justifyContent: `space-between`,
                  listStyle: `none`,
                  padding: 0,
                }}
              >
                <li>
                  {previous && (
                    <Link to={previous.fields.slug} className="text-base" rel="prev">
                      ← {previous.frontmatter.title}
                    </Link>
                  )}
                </li>
                <li>
                  {next && (
                    <Link to={next.fields.slug} rel="next">
                      {next.frontmatter.title} →
                    </Link>
                  )}
                </li>
              </ul>
            </footer> */}
              {/* <!--/ Post Content--> */}
            </div>

            {/* <!--Subscribe-->	 */}
            {/* <div className="container font-sans bg-green-100 rounded mt-8 p-4 md:p-24 text-center">
            <h2 className="font-bold break-normal text-2xl md:text-4xl">Subscribe to Ghostwind CSS</h2>
            <h3 className="font-bold break-normal font-normal text-gray-600 text-base md:text-xl">Get the latest posts delivered right to your inbox</h3>
            <div className="w-full text-center pt-4">
              <form action="#">
                <div className="max-w-sm mx-auto p-1 pr-0 flex flex-wrap items-center">
                  <input type="email" placeholder="youremail@example.com" className="flex-1 appearance-none rounded shadow p-3 text-gray-600 mr-2 focus:outline-none" />
                  <button type="submit" className="flex-1 mt-4 md:mt-0 block md:inline-block appearance-none bg-green-500 text-white text-base font-semibold tracking-wider uppercase py-4 rounded shadow hover:bg-green-400">Subscribe</button>
                </div>
              </form>
            </div>
          </div> */}
            {/* <!-- /Subscribe--> */}

            {/* <!--Author--> */}
            <Bio />
            {/* <!--/Author--> */}
          </div>
        </div>
      </article>
    </PostLayout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      tableOfContents(
        absolute: false
        pathToSlugField: "frontmatter.path"
        maxDepth: 2
      )
      frontmatter {
        title
        date(formatString: "YYYY/MM/DD")
        keywords
        modifieddate(formatString: "yyyy/MM/DD")
        description
      }
      fields {
        readingTime {
          text
        }
        slug
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`;
